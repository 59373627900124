import 'tailwindcss/tailwind.css';
import App from 'next/app';
import { getSession, UserProvider } from '@auth0/nextjs-auth0';
import { hotjar } from 'react-hotjar';
import { useEffect } from 'react';

function MyApp({ Component, pageProps }) {
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_API_URL === 'https://slotit.herokuapp.com') {
      hotjar.initialize(2853361, 1);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('accessToken', pageProps.accessToken);
  }, [pageProps.accessToken]);

  return (
    <UserProvider>
      <Component {...pageProps} />
    </UserProvider>
  );
}

MyApp.getInitialProps = async (appContext: any) => {
  const appProps = await App.getInitialProps(appContext);
  const session = getSession(appContext.ctx.req, appContext.ctx.res);

  if (!session) {
    return {
      pageProps: {
        ...appProps.pageProps,
      },
    };
  }

  return {
    pageProps: {
      ...appProps.pageProps,
      accessToken: session ? session.accessToken : null,
    },
  };
};

export default MyApp;
